import IconButton from '@mui/material/IconButton';
import Stack, { StackProps } from '@mui/material/Stack';
// @mui
import Typography from '@mui/material/Typography';
import { useQueryState } from 'nuqs';
import { useCallback, useState } from 'react';
import { useGetCities } from 'src/api/city';
import TopDialog from 'src/components/dialog/top-dialog';
import EventSearch from 'src/components/event-search/event-search';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useTranslate } from 'src/locales';
import { useLocationStore } from 'src/stores/useLocationStore';

import { IEventTag } from '../../models/event-tag.model';
import FiltersDialog from './filters-dialog';
import LocationAndSearch from './location-search';

interface Props extends StackProps {
  filters: IEventTag[];
}

export default function FiltersBar({ filters }: Props) {
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const { data: CitiesList } = useGetCities();
  const { t } = useTranslate('common');
  const { place, setLat, setLng, setPlace } = useLocationStore();

  const showEventSearch = useBoolean();
  const showEventsFilters = useBoolean();
  const mdDown = useResponsive('down', 'md');

  const [___, setTagsQuery] = useQueryState<string[]>('tags', {
    history: 'push',
    parse: (value) => (value ? value.split(',') : []),
    serialize: (value) => value.join(','),
  });

  const onSelectTag = (tag: string) => {
    let newActiveFilters = [...activeFilters];

    if (newActiveFilters.includes(tag)) {
      newActiveFilters = newActiveFilters.filter((item) => item !== tag);
    } else {
      newActiveFilters.push(tag);
    }

    setActiveFilters(newActiveFilters);
    setTagsQuery(newActiveFilters.length > 0 ? newActiveFilters : null);
  };

  const addQuery = useCallback(
    (location: { lat: number; lng: number; place?: string }) => {
      setLat(location.lat.toString());
      setLng(location.lng.toString());
      setPlace(place);
    },
    [place, setLat, setLng, setPlace]
  );

  return (
    <Stack spacing={1} px={{ xs: 1, md: 0 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={{ xs: 1, md: 0 }}
      >
        <LocationAndSearch data={CitiesList} addQuery={addQuery} />
        {mdDown && (
          <Stack direction="row" gap={1}>
            <IconButton
              sx={{
                backgroundColor: '#000',
                borderRadius: 1,
                color: 'white',
              }}
              onClick={showEventSearch.onTrue}
            >
              <Iconify width={20} icon="icon-park-outline:search" />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: '#000',
                borderRadius: 1,
                color: 'white',
              }}
              onClick={showEventsFilters.onTrue}
            >
              <Iconify width={20} icon="mi:filter" />
            </IconButton>
          </Stack>
        )}
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        sx={{
          pb: 0.5,
        }}
      >
        <Scrollbar>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              overflow: 'auto',
              scrollbarWidth: 'none',
              msOverflowStyle: {
                display: 'none',
              },
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '&-ms-overflow-style:': {
                display: 'none',
              },
              '& ::-webkit-scrollbar-track': {
                background: 'transparent',
              },
            }}
          >
            {filters.map((filter) => (
              <Stack
                direction="row"
                key={filter.id}
                spacing={1}
                alignItems="center"
                onClick={() => onSelectTag(filter.id)}
                sx={{
                  py: 1,
                  px: 2,
                  borderRadius: 1,
                  minWidth: 'fit-content',
                  bgcolor: activeFilters.includes(filter.id) ? 'white' : '#000',
                  cursor: 'pointer',
                  position: 'relative',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 650,
                    color: (theme) =>
                      activeFilters.includes(filter.id) ? '#000' : theme.palette.common.white,
                  }}
                >
                  {t(`filters.${filter.name}`)}
                </Typography>
                <Iconify
                  width={20}
                  icon={filter.icon}
                  sx={{
                    color: (theme) =>
                      activeFilters.includes(filter.id) ? '#000' : theme.palette.common.white,
                    transition: (theme) =>
                      theme.transitions.create('all', {
                        duration: theme.transitions.duration.shortest,
                      }),
                  }}
                />
              </Stack>
            ))}
          </Stack>
        </Scrollbar>
        {!mdDown && (
          <Stack direction="row" gap={1}>
            <IconButton
              sx={{
                backgroundColor: '#000',
                borderRadius: 1,
                color: 'white',
              }}
              onClick={showEventSearch.onTrue}
            >
              <Iconify width={20} icon="icon-park-outline:search" />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: '#000',
                borderRadius: 1,
                color: 'white',
              }}
              onClick={showEventsFilters.onTrue}
            >
              <Iconify width={20} icon="mi:filter" />
            </IconButton>
          </Stack>
        )}
        <TopDialog dialog={showEventSearch}>
          <EventSearch dialog={showEventSearch} />
        </TopDialog>
      </Stack>
      <FiltersDialog
        open={showEventsFilters.value}
        onClose={showEventsFilters.onFalse}
        addQuery={addQuery}
      />
    </Stack>
  );
}
