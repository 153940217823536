import useSWR from 'swr';

import { AggregatedCityModel } from '../types';
import { endpoints, fetcher } from '../utils/axios';

export function useGetCities(withEventsCount?: boolean) {
  const url = endpoints.cities.findAll;
  return useSWR<AggregatedCityModel[]>(`${url}`, fetcher, {
    keepPreviousData: false,
    revalidateOnFocus: true,
  });
}
