import Dialog from '@mui/material/Dialog';
import React, { forwardRef } from 'react';
// @mui
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
// hooks
// components

// ----------------------------------------------------------------------
type Props = {
  children: React.ReactNode;
  dialog: any;
};
const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="down" ref={ref} {...props} />
);

export default function TopDialog({ children, dialog }: Props) {
  return (
    <Dialog
      open={dialog.value}
      onClose={dialog.onFalse}
      TransitionComponent={Transition}
      // fullScreen
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        '& .MuiDialog-container': { alignItems: 'flex-start' },
      }}
    >
      {children}
    </Dialog>
  );
}
