import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface LocationState {
  lat: string | null;
  lng: string | null;
  place: string | null;
  setLat: (lat: string) => void;
  setLng: (lng: string) => void;
  setPlace: (place: string | null) => void;
}

export const useLocationStore = create<LocationState>()(
  persist(
    (set) => ({
      lat: null,
      lng: null,
      place: null,
      setLat: (lat) => set({ lat }),
      setLng: (lng) => set({ lng }),
      setPlace: (place) => set({ place }),
    }),
    {
      name: 'location-storage', // Name of item in local storage
      partialize: (state) => ({ lat: state.lat, lng: state.lng, place: state.place }), // Persist only lat, lng, place
    }
  )
);
