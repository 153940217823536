import algoliasearch from 'algoliasearch';

import { ALGOLIA_API } from '../../config-global';
import { Address, City, IEvent, IOrganization } from '../../types';

export type EventRecord = Pick<
  IEvent,
  'title' | 'cover' | 'date' | 'tags' | 'description' | 'id' | 'startDate' | 'endDate' | 'timezone'
> & { address: Address };

export type OrganizationRecord = Pick<IOrganization, 'id' | 'logo' | 'name' | 'normalizedName'>;
export type CityRecord = City & {
  _geoloc: {
    lat: number;
    lng: number;
  };
};
export type AlgoliaRecord = EventRecord | OrganizationRecord | CityRecord;

const client = algoliasearch(ALGOLIA_API.appId, ALGOLIA_API.apiKey);
const eventIndex = client.initIndex('production');
const cityIndex = client.initIndex('cities');
const organizationIndex = client.initIndex('organizations');

export function getEventSearchFilters(): string {
  const oneDayInMS = 24 * 60 * 60 * 1000;
  const date = `dateTimestamp >= ${Date.now() - oneDayInMS} `;

  return `${date}`;
}

export const useAlgolia = () => ({
  eventIndex,
  cityIndex,
  organizationIndex,
  client,
});
